import { ExportToCsv } from 'export-to-csv'
import numeral from 'numeral'
import moment from 'moment'

export async function saveCardToFile(card: any) {
    const exchange = card.channel.split('/')[1],
        cross = card.channel.split('/')[2],
        ticker = card.channel.split('/')[3],
        displayCross = cross === 'imbO' ? 'O' : 'C',
        displayExchange = exchange === 'nyse' ? 'NYSE' : 'NASDAQ',
        date = moment(Date.now()).format('YYYY-MM-DD'),
        data = card.data

    let newData = []

    for (var i = 0; i < data.length; i++) {
        let objectOrder = {
            t: null,
            date: null,
            exchange: null,
            cross: null,
            ticker: null,
            p: null,
            i: null,
            u: null,
            r: null,
            n: null,
            f: null,
        }
        let newObj = Object.assign(objectOrder, data[i])
        const newTime = newObj.t + (newObj.m === 'Q' ? 14400 : 0) + newObj.u / 1000000
        const newDollars = newObj.i * newObj.r
        newObj.t = moment(newTime * 1000).format('h:mm:ss.SSS')
        newObj.date = date
        newObj.exchange = displayExchange
        newObj.cross = displayCross
        newObj.ticker = ticker
        newObj.p = numeral(newObj.p).format('00')
        newObj.i = numeral(newObj.i).format('00')
        newObj.u = numeral(newDollars).format('00')
        newObj.r = numeral(newObj.r).format('00.00')
        newObj.n = numeral(newObj.n).format('00.00')
        newObj.f = numeral(newObj.f).format('00.00')
        delete newObj.m
        delete newObj.s
        delete newObj.ssr
        newData.push(newObj)
    }

    const options = {
        fieldSeparator: ',',
        filename: `${displayExchange}_${displayCross}_${ticker}_${date}`,
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: `${ticker} ${displayCross} ${displayExchange} ${date}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Time', 'Date', 'Exchange', 'Cross', 'Ticker', 'Paired', 'Imb', 'Imb $$', 'Ref Px', 'Near Px', 'Far Px'],
    }
    const csvExporter = new ExportToCsv(options)
    //console.log(`csvExporter.generateCsv = `, newData)

    return new Promise(function (resolve, reject) {
        csvExporter.generateCsv(newData)
        setTimeout(function () {
            resolve()
        }, 500)
    })
}

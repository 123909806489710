interface Datahub {
    connected: any
    setDataStateHandler: any
    setSubscribeHandler: any
    connect_handler: any
    ws: any
}

class Datahub {
    constructor() {
        this.connected = false
    }

    connect(uri: any, handler: any) {
        this.connect_handler = handler
        var datahub = this

        if ('WebSocket' in window) {
            datahub.ws = new WebSocket(uri, 'net.neomantra.datahub')
        } else if ('MozWebSocket' in window) {
            datahub.ws = new MozWebSocket(uri)
        } else {
            datahub.connect_handler({ status: 'error', msg: "This browser doesn't support WebSockets" })
            return
        }

        var subprotocol_error = (errmsg) => {
            datahub.ws.close(3000, 'Datahub server subprotocol error: ' + errmsg)
            datahub.connect_handler({ status: 'error', msg: 'Datahub server subprotocol error: ' + errmsg })
        }

        datahub.ws.onopen = (event: any) => {
            datahub.connected = true
            datahub.connect_handler({ status: 'open' })
        }
        datahub.ws.onerror = (event: any) => {
            console.log('On error event: ', event)
            datahub.connected = false
            datahub.connect_handler({ status: 'error', msg: 'The WebSocket connection failed for some reason.' })
        }
        datahub.ws.onclose = (event: any) => {
            datahub.connected = false
            datahub.connect_handler({ status: 'closed', code: event.code, reason: event.reason })
        }
        datahub.ws.onmessage = (event: any) => {
            var data: any
            try {
                //data = JSON.parse(e.data)
                data = JSON.parse(event.data.replace(/[+]/g, ''))
            } catch (err) {
                console.log(`got invalid JSON. this is a bad protocol error `, err)
                // got invalid JSON. this is a bad protocol error
                subprotocol_error(err.message)
                return
            }
            if (data.type === 'update') {
                this.setSubscribeHandler(data)
            } else {
                data.data.reverse()
                this.setDataStateHandler(data)
            }
        }
    }

    disconnect() {
        this.ws.close(1000)
    }

    sendRequest(setMessageHistory: any, channel: string) {
        this.setDataStateHandler = setMessageHistory

        const req = { type: 'history', token: 'foo', channel: channel }
        this.send(JSON.stringify(req), undefined)

        // generates random history per the method below
        // this.sendTestRequest(setMessageHistory, channel)
    }

    sendTestRequest(setDataStateHandler: any, channel: string) {
        this.setDataStateHandler = setDataStateHandler
        const paired = Math.floor(Math.random() * 100000),
            imbalance = Math.floor(Math.random() * 10000),
            ref = Math.floor(Math.random() * 250),
            near = Math.floor(Math.random() * 250),
            far = Math.floor(Math.random() * 250)

        const data = [{ f: far, i: imbalance, m: 'Q', n: near, p: paired, r: ref, s: Date.now() / 1000 - 14400, t: Date.now() / 1000 - 14400, u: 56808 }]
        const req = { type: 'history', channel: '/nyse/imbO/BABA', token: 'foo', data: data }
        this.setDataStateHandler(req)
    }

    sendSubscribe(subscribeHandler: any, channel: string) {
        this.setSubscribeHandler = subscribeHandler

        const req = { type: 'subscribe', token: 'sub', channel: channel }
        this.send(JSON.stringify(req), undefined)

        // generates random subscribe updates per the method below
        // this.sendTestSubscribe(subscribeHandler, channel)
    }

    sendTestSubscribe(subscribeHandler: any, channel: string) {
        this.setSubscribeHandler = subscribeHandler
        const int = setInterval(() => {
            const paired = Math.floor(Math.random() * 100000),
                imbalance = Math.floor(Math.random() * 10000),
                ref = Math.floor(Math.random() * 250),
                near = Math.floor(Math.random() * 250),
                far = Math.floor(Math.random() * 250)

            const data = [{ f: far, i: imbalance, m: 'Q', n: near, p: paired, r: ref, s: Date.now() / 1000 - 14400, t: Date.now() / 1000 - 14400, u: 56808 }]
            const req = { type: 'update', channel: '/nyse/imbO/BABA', token: 'foo', data: data }
            this.setSubscribeHandler(req)
        }, 2500)
    }

    send(message: any, callback: any) {
        this.waitForConnection(() => {
            this.ws.send(message)
            if (typeof callback !== 'undefined') {
                callback()
            }
        }, 2000)
    }

    waitForConnection(callback: any, interval: any) {
        //console.log(`waitForConnection ws.readyState = ${this.ws.readyState}`)
        if (this.ws.readyState === 1) {
            callback()
        } else {
            setTimeout(() => {
                this.waitForConnection(callback, interval)
            }, interval)
        }
    }
}

export default Datahub

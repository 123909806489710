import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../app/store'
import { saveCardToFile } from '../../api/saveFileAPI'

interface CardState {
    loading: boolean
    error: string
}

const initialState: CardState = {
    loading: false,
    error: null,
}

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        saveCardToFileStart(state) {
            state.loading = true
            state.error = null
        },
        saveCardToFileSuccess(state) {
            state.loading = false
            state.error = null
        },
        saveCardToFileFailure(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    },
})

export const { saveCardToFileStart, saveCardToFileSuccess, saveCardToFileFailure } = cardSlice.actions

export default cardSlice.reducer

export const exportCardToFile = (card: any): AppThunk => async (dispatch) => {
    try {
        dispatch(saveCardToFileStart())
        const fileSaved = await saveCardToFile(card)
        dispatch(saveCardToFileSuccess())
    } catch (err) {
        dispatch(saveCardToFileFailure(err))
    }
}

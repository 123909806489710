import { combineReducers } from '@reduxjs/toolkit'

import toolBarReducer from '../features/toolBar/toolBarSlice'
import cardReducer from '../features/cardGrid/cardSlice'

const rootReducer = combineReducers({
    console: toolBarReducer,
    card: cardReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer

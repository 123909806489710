import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MessagePayload {
    type: string
    token: string
    channel: string
    data: any[]
}

interface ToolBarState {
    cardStack: Array<any | undefined>
    cardStackData: Array<any | undefined>
}

const initialState: ToolBarState = {
    cardStack: [],
    cardStackData: [],
}

const toolBarSlice = createSlice({
    name: 'console',
    initialState,
    reducers: {
        addCard(state, action: PayloadAction<MessagePayload>) {
            const msg = action.payload
            state.cardStack.push(msg)
            state.cardStackData.push(msg)
        },
        updateCard(state, action: PayloadAction<MessagePayload>) {
            const { channel, data } = action.payload
            state.cardStack.map((val: any, index: number) => {
                if (val.channel === channel) {
                    state.cardStack[index].data.unshift(data[0])
                }
            })
        },
        removeCard(state, action: PayloadAction<number>) {
            const index = action.payload
            state.cardStack.splice(index, 1)
            state.cardStackData.splice(index, 1)
        },
    },
})

export const { addCard, updateCard, removeCard } = toolBarSlice.actions

export default toolBarSlice.reducer

import DataHubConnect from './datahub'

interface DatahubManager {
    connected: any
    last_status: any
    dataHubConnect: any
    setConnected: any
    onConnectError: any
}

class DatahubManager {
    constructor() {
        this.connected = false
        this.last_status = 'unconnected'
        this.dataHubConnect = new DataHubConnect()
        return this
    }

    startNewVotive(votive: any, setIsConnected: any, onConnectError: any) {
        this.onConnectError = onConnectError
        this.setConnected = setIsConnected
        let url = votive
        this.dataHubConnect.disconnect()
        this.dataHubConnect.connect(url, this.onConnectStatus)
    }

    start(votive: any, setIsConnected: any, onConnectError: any) {
        this.onConnectError = onConnectError
        this.setConnected = setIsConnected
        let url = votive
        if (!this.dataHubConnect.connected) {
            console.log('Datahub server url: ', url)
            this.dataHubConnect.connect(url, this.onConnectStatus)
        }
    }

    sendRequest(setMessageHistory: any, channel: string) {
        this.dataHubConnect.sendRequest(setMessageHistory, channel)
    }

    sendSubscribe(subscribeHandler: any, channel: string) {
        this.dataHubConnect.sendSubscribe(subscribeHandler, channel)
    }

    getLastStatus() {
        return this.last_status
    }

    onConnectStatus = (result: any) => {
        this.last_status = result.status

        if (result.status !== 'open') {
            console.log('could not connect to datahub: ', result.status)
            this.connected = false
            this.setConnected(this.connected)
            this.onConnectError(result.status, result.code, result.reason)
            return
        }

        console.log('connected to datahub', result)
        this.connected = true
        this.setConnected(this.connected)
    }

    close() {
        console.log('closing connection to  to datahub')
        this.dataHubConnect.disconnect()
        this.connected = false
        this.setConnected(this.connected)
    }
}

export default DatahubManager

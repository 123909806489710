// index.tsx
import React from 'react'
import ReactDOM from 'react-dom'
import App from './src/app/App'
import WebFont from 'webfontloader'

WebFont.load({
    google: {
        families: ['IBM Plex Mono:300,400,700', 'sans-serif'],
    },
})

ReactDOM.render(
    <div>
        <App />
    </div>,
    document.getElementById('root')
)
